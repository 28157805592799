import React from 'react'
import './App.css';


export function TermsOfService(props) {
    return(
        <div className="App">

            <header>
    <h1>FYL.LA Terms of Service</h1>
    </header>

    

   <nav>
    <h1 id="contents">Contents</h1>

     <ul>
     <li><a href="#introduction">Introduction</a>
      </li>

     <li><a href="#use-of-the-service">Use of the Service</a>
      </li>

      <li><a href="#content">Content</a>
      </li>

      <li><a href="#intellectual-property">Intellectual Property</a>
      </li>

      <li><a href="#termination">Termination</a>
      </li>

      <li><a href="#limitation-of-liability">Limitation of Liabilityy</a>
      </li>

      <li><a href="#disclaimer-of-warranties">Disclaimer of Warranties</a>
      </li>

      <li><a href="#indemnification">Indemnification</a>
      </li>

      <li><a href="#governing-law-and-jurisdiction">Governing Law and Jurisdiction</a>
      </li>

      <li><a href="#changes-to-the-terms-of-service">Changes to the Terms of Service</a>
      </li>

      <li><a href="#contact-information">Contact Information</a>
      </li>
     
     </ul>
    </nav>
   
   <article>

    <section>

     <h1 id="introduction">Introduction</h1>

     <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the Fyl.la website and app (the "Service") operated by Fyl.la ("us", "we", or "our").

Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you do not have permission to access the Service.</p>

     </section>
   
   
    <section>
     <h1 id="use-of-the-service">Use of the Service</h1>

    <p>
    1.1. You are responsible for your own use of the Service and for any consequences thereof. You agree to use the Service only for purposes that are legal, proper, and in accordance with these Terms and any applicable laws and regulations.
    </p>

    <p>
    1.2. You may not use the Service in any manner that could damage, disable, overburden, or impair the Service or interfere with any other party's use and enjoyment of the Service.
    </p>

    <p>1.3. You agree not to access (or attempt to access) the Service by any means other than through the interface provided by us, unless you have been specifically allowed to do so in a separate written agreement with us.</p>

     </section>

    <section>
     <h1 id="content">Content</h1>

        <p>2.1. You are solely responsible for the content, including but not limited to text, and any other materials that you submit, post, or display on or through the Service (the "Content").</p>
        <p>2.2. You agree that you have all necessary rights to the Content and that you are not infringing or violating any third party's rights by posting it.</p>
        <p>2.3. We reserve the right, but are not obligated, to review, modify, or remove any Content that we deem, in our sole discretion, to be inappropriate, offensive, harmful, or otherwise in violation of these Terms.</p>

        
     </section>

    <section>
     <h1 id="intellectual-property">Intellectual Property</h1>

      <p>3.1. The Service and its original content, features, and functionality are and will remain the exclusive property of Fyl.la and its licensors. The Service is protected by copyright, trademark, and other laws of both Norway and other foreign countries.</p>
      <p>3.2. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Service, except as incidental to your use of the Service as intended.</p>

     </section>

    <section>
     <h1 id="termination">Termination</h1>

      <p>4.1. We may terminate or suspend your access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever, including but not limited to a breach of the Terms.</p>
      <p>4.2. Upon termination, your right to use the Service will immediately cease.</p>

     </section>

    <section>
     <h1 id="limitation-of-liability">Limitation of Liability</h1>

      <p>5.1. In no event shall Fyl.la, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose</p>

     </section>

    <section>
     <h1 id="disclaimer-of-warranties">Disclaimer of Warranties</h1>

      <p>6.1. Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>
        <p>6.2. Fyl.la, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure, or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
    
     </section>

    <section>
     <h1 id="indemnification">Indemnification</h1>

      <p>7.1. You agree to defend, indemnify, and hold harmless Fyl.la, its directors, employees, partners, agents, suppliers, and affiliates from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service; b) a breach of these Terms; or c) your violation of any third-party right, including without limitation any copyright, property, or privacy right.</p>

     </section>

    <section>
     <h1 id="governing-law-and-jurisdiction">Governing Law and Jurisdiction</h1>

      <p>8.1. These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which Fyl.la operates, without regard to its conflict of law provisions.</p>
      <p>8.2. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service.</p>

     </section>

    <section>
     <h1 id="changes-to-the-terms-of-service">Changes to the Terms of Service</h1>

      <p>9.1. We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

      <p>9.2. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>

     </section>

    <section>
     <h1 id="contact-information">Contact Information</h1>

      <p>10.1. If you have any questions about these Terms, please contact us via mail at fyl.la.contact@gmail.com.

By using the Fyl.la website and app, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>

     </section>

   </article>
    
    
    
    <br></br>
    <nav className="to-top"><a href="#contents">back to top</a></nav>
    <div className='background'></div>
        </div>
    )
}