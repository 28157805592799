import React from 'react'
import './App.css';


export function PrivacyPolicy(props) {
    return(
        <div className="App">

            <header>
    <h1>FYL.LA Privacy Policy</h1>
    </header>

   <nav>
    <h1 id="contents">Contents</h1>

     <ul>
     <li><a href="#introduction">Introduction</a>
      <ul><li><a href="#purpose-of-the-privacy-policy">Purpose of the Privacy Policy</a></li></ul>
      </li>

     <li><a href="#information-we-collect">Information We Collect</a>
      <ul><li><a href="#non-personal-information">Non-Personal Information</a></li></ul>
      <ul><li><a href="#log-files-and-usage-data">Log Files and Usage Data</a></li></ul>
      </li>

      <li><a href="#how-we-use-the-information">How We Use the Information</a>
      </li>

      <li><a href="#information-sharing">Information Sharing</a>
      </li>

      <li><a href="#cookies-and-other-tracking-technologies">Cookies and Other Tracking Technologies</a>
      </li>

      <li><a href="#information-storage-and-security">Information Storage and Security</a>
      </li>

      <li><a href="#user-rights">User Rights</a>
      </li>

      <li><a href="#contact-us">Contact Us</a>
      </li>

      <li><a href="#third-party-services">Third-Party Services</a>
      </li>

      <li><a href="#age-restriction">Age Restriction</a>
      </li>

      <li><a href="#legal-requirements">Legal Requirements</a>
      </li>

      <li><a href="#changes-to-this-privacy-policy">Changes to This Privacy Policy</a>
      </li>
     
     </ul>
    </nav>
   
   <article>

    <section>

     <h1 id="introduction">Introduction</h1>

      <h2 id="purpose-of-the-privacy-policy">Purpose of the Privacy Policy</h2>
      <p>
      Fyl.la ("we", "us", or "our") is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, store, and safeguard your non-personal information when you use our app. Please read this Privacy Policy carefully. By using our app, you agree to the terms of this Privacy Policy.
      </p>

     </section>
   
   
    <section>
     <h1 id="information-we-collect">Information We Collect</h1>

      <h2 id="non-personal-information">Non-Personal Information</h2>
      
      <p>We collect non-personal information from users when they input data of their choice as part of the game. We do not collect any personal information, such as names or contact details.</p>

      <h2 id="log-files-and-usage-data">Log Files and Usage Data</h2>

      <p>We may collect non-personal information about your use of the app, such as the number of times you access the app, the duration of your sessions, and other usage statistics. This information may include your device's Internet Protocol (IP) address, browser type, operating system, and other device-related information.</p>

     </section>

    <section>
     <h1 id="how-we-use-the-information">How We Use the Information</h1>

     <p>We use the collected non-personal information for the following purposes:

To provide our game service and enhance the user experience.
To monitor and analyze the use of our app in order to improve its functionality and performance.
To detect, prevent, and address technical issues or potential security risks.
Information Sharing

We do not share the collected information with any third parties.</p>


        
     </section>

    <section>
     <h1 id="information-sharing">Information Sharing</h1>

      <p>We do not share the collected information with any third parties.</p>

     </section>

    <section>
     <h1 id="cookies-and-other-tracking-technologies">Cookies and Other Tracking Technologies</h1>

      <p>We may use cookies and other tracking technologies to collect and store non-personal information about your use of the app. Cookies are small text files that are placed on your device by websites that you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.

You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our app.</p>

     </section>

    <section>
     <h1 id="information-storage-and-security">Information Storage and Security</h1>

      <p>The collected information is stored on a private server that hosts the web application. We take appropriate measures to protect the information from unauthorized access, alteration, disclosure, or destruction. These measures may include encryption, access controls, and other security technologies.</p>

     </section>

    <section>
     <h1 id="user-rights">User Rights</h1>

      <p>As we do not collect any personal information, there is no need for users to access, update, or delete their information.</p>

     </section>

    <section>
     <h1 id="contact-us">Contact Us</h1>

      <p>If you have any questions or concerns about this Privacy Policy, you can contact us via mail at fyl.la.contact@gmail.com.</p>

     </section>

    <section>
     <h1 id="third-party-services">Third-Party Services</h1>

      <p>We do not use any third-party services that collect information on our behalf.</p>

     </section>

    <section>
     <h1 id="age-restriction">Age Restriction</h1>

      <p>We do not have any age restrictions for using our app.</p>

     </section>

    <section>
     <h1 id="legal-requirements">Legal Requirements</h1>

      <p>No specific legal requirements or regulations apply to our app.</p>

     </section>

    <section>
     <h1 id="changes-to-this-privacy-policy">Changes to This Privacy Policy</h1>

      <p>We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the updated Privacy Policy on our app. Your continued use of our app after any such changes constitutes your acceptance of the updated Privacy Policy.</p>

     </section>

   </article>
    
    
    
    <br></br>
    <nav className="to-top"><a href="#contents">back to top</a></nav>
    <div className='background'></div>
        </div>
    )
}