export const wordStyles = {
    oslo: "allcaps",
    lillehammer: "allcaps",
    bodø: "allcaps",

    alle: "bold",
    beste: "bold",
    fortell: "bold",

    enhet: "bold",

    ferdinand: "bold outline",
    markus: "bold outline",



    "2023": "bold allcaps",
    vors: "bold allcaps",
    byen: "bold allcaps",

    full: "entry-black bold",
    værste: "entry-black bold",
    taper: "entry-black bold",
    fylla: "entry-black bold",
    kleineste: "entry-black bold",
    whiskey: "entry-black bold",
    vodka: "entry-black bold",
    jævla: "entry-black bold",
    kill: "entry-black bold",

    drita: "entry-black allcaps bold",
    bonski: "entry-black allcaps bold",

    dealbreaker: "entry-black allcaps bold",
    bodycount: "entry-black allcaps bold",
    faen: "entry-black allcaps bold",
    shit: "entry-black allcaps bold",
    utro: "entry-black allcaps bold",
    fullest: "entry-black allcaps bold",

    horer: "entry-black allcaps bold",
    hore: "entry-black allcaps bold",
    shot: "entry-black allcaps bold",
    chugge: "entry-black allcaps bold",
    shotte: "entry-black allcaps bold",
    chug: "entry-black allcaps bold",
    chugg: "entry-black allcaps bold",
    shots: "entry-black allcaps bold",
    chugger: "entry-black allcaps bold",
    bodyshot: "entry-black allcaps bold",
    blackout: "entry-black allcaps bold",

    slurker: "entry-drikk bold",
    drikk: "entry-drikk bold",
    drikker: "entry-drikk bold",
    drikke: "entry-drikk bold",
    skåler: "entry-drikk bold",
    slurk: "entry-drikk bold",
    sips: "entry-drikk bold",
    drink: "entry-drikk bold",
    slurka: "entry-drikk bold",
    drikken: "entry-drikk bold",
    resten: "entry-drikk bold",
    glasset: "entry-drikk bold",
    drikka: "entry-drikk bold",
    drukket: "entry-drikk bold",
    sip: "entry-drikk bold",

    skål: "bold allcaps",

    truth: "entry-blue bold",
    dare: "entry-blue bold",
    dares: "entry-blue bold",
    rate: "entry-blue bold",
    story: "entry-blue bold",
    drikkepartner: "entry-blue bold",

    pekelek: "entry-blue bold allcaps",
    waterfall: "entry-blue bold allcaps",
    kategori: "entry-blue bold allcaps",
    kategorilek: "entry-blue bold allcaps",
    storytime: "entry-blue bold allcaps",
    tema: "entry-blue bold allcaps",
    hotseat: "entry-blue bold allcaps",

    fuck: "entry-red bold",
    pult: "entry-red bold",
    pule: "entry-red bold",
    slikke: "entry-red bold",
    digg: "entry-red bold",
    ligg: "entry-red bold",
    sug: "entry-red bold",
    sexstilling: "entry-red bold",
    sexstillingen: "entry-red bold",
    sugd: "entry-red bold",
    suge: "entry-red bold",
    tissen: "entry-red bold",
    ligge: "entry-red bold",
    ligget: "entry-red bold",
    penis: "entry-red bold",
    anal: "entry-red bold",
    knull: "entry-red bold",
    knulla: "entry-red bold",
    rompe: "entry-red bold",
    rompa: "entry-red bold",

    sex: "entry-red bold",
    kuk: "entry-red bold",
    kukk: "entry-red bold",
    slikk: "entry-red bold",
    lapdance: "entry-red bold",
    threesome: "entry-red bold",

    pikk: "entry-red bold allcaps",
    fitte: "entry-red bold allcaps",
    ons: "entry-red bold allcaps",

    elsker: "entry-pink bold",
    date: "entry-pink bold",
    kompliment: "entry-pink bold",
    single: "entry-pink bold",
    tinder: "entry-pink bold",
    diggest: "entry-pink bold",
    favoritt: "entry-pink bold",
    finest: "entry-pink bold",
    penest: "entry-pink bold",
    kyssa: "entry-pink bold",
    peneste: "entry-pink bold",
    kjæreste: "entry-pink bold",
    sexpartnere: "entry-pink bold",
    sexhistorie: "entry-pink bold",
    kysser: "entry-pink bold",
    kysset: "entry-pink bold",
    rumpa: "entry-pink bold",
    twerk: "entry-pink bold",
    sjekkereplikk: "entry-pink bold",
    crush: "entry-pink bold",
    sexy: "entry-pink bold",
    marry: "entry-pink bold",
    gifte: "entry-pink bold",
    vakker: "entry-pink bold",
    kåt: "entry-pink bold",
    kåtest: "entry-pink bold",
    kyss: "entry-pink bold",
    kiss: "entry-pink bold",
    kysse: "entry-pink bold",
    kasper: "entry-pink bold",
    hot: "entry-pink bold",

    redflag: "entry-red bold allcaps",
    red: "entry-red bold allcaps",
    flag: "entry-red bold allcaps",
    spydd: "entry-puke bold",
    ok: "entry-green bold allcaps",
    bursdag: "bold",

    øl: "entry-beer bold",
    vin: "entry-wine bold",
    ølen: "entry-beer bold",
    vinen: "entry-wine bold",
    blonde: "entry-yellow bold",
    snapchat: "entry-snap bold",
    snap: "entry-snap bold",
    ølla: "entry-beer bold",

    gay: "entry-purple bold",
    snus: "entry-snus bold",
    homo: "entry-purple bold",

    brunetter: "entry-brown bold",
    baris: "entry-pink bold",

    kjekkest: "entry-pink bold",
    fireball: "entry-orange bold",
}