import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      norsk: {
        translation: {
          "no-exist": "Rommet eksisterer ikke",
          "taken": "Romnavnet er tatt",
          "nothing": "Ingenting er lagt til",

          "empty": "Rommet er tomt 😭",

          "added": "Lagt til",
          "language-changed": "Språk byttet",

          "new-room": "LAG NYTT ROM",
          "join-room": "JOIN ROM MED ID",

          "back": "TILBAKE",
          "create": "LAG",
          "room-id": "ROM-ID",
          "start": "START",

          "how-to-play": "FORKLARING",
          "how1": "I drikkeleken joiner alle samme rom-id, og legger inn spørsmål eller dares.",
          "how2": "Når alle er klare starter dere på en mobil og sender den rundt.",
          "write-here": "SKRIV HER . . .",
          "add": "LEGG TIL",
          "next": "NESTE",

          "leave-room": "FORLAT ROMMET",
          "done1": "FERDIG?",
          "done2": "Du har ikke spilt ferdig runden.",
          "keep-playing": "FORTSETT",

          "privacy-policy": "PERSONVERN",
          "terms-of-service": "VILKÅR",
          "menu": "MENY",

          "tips": "Forslag",

          "rec-1-preview": "Pekelek",
          "rec-2-preview": "Jeg har aldri",
          "rec-3-preview": "Red flag, dealbreaker, ok",
          "rec-4-preview": "Hvem i rommet . . .",
          "rec-5-preview": "Kategorilek",
          "rec-6-preview": "Waterfall",
          "rec-7-preview": "Alle jenter . . .",
          "rec-8-preview": "Alle gutter . . .",
          "rec-9-preview": "Alle som . . .",
          "rec-10-preview": "Alle single . . .",
          "rec-11-preview": "Alle med kjæreste . . .",
          "rec-12-preview": "Den yngste . . .",
          "rec-13-preview": "Den eldste . . .",
          "rec-14-preview": "Shot",
          "rec-15-preview": "Del ut 3 slurker . . .",
          "rec-16-preview": "Fuck, marry, kill . . .",
          "rec-17-preview": "Tommel opp / ned",


          "rec-1-text": "Pekelek: ",
          "rec-2-text": "Jeg har aldri ",
          "rec-3-text": "Red flag, dealbreaker, ok: ",
          "rec-4-text": "Hvem i rommet ",
          "rec-5-text": "Kategori: ",
          "rec-6-text": "Waterfall",
          "rec-7-text": "Alle jenter ",
          "rec-8-text": "Alle gutter ",
          "rec-9-text": "Alle som ",
          "rec-10-text": "Alle single ",
          "rec-11-text": "Alle med kjæreste ",
          "rec-12-text": "Den yngste ",
          "rec-13-text": "Den eldste ",
          "rec-14-text": "Shot",
          "rec-15-text": "Del ut 3 slurker ",
          "rec-16-text": "Fuck, marry, kill: ",
          "rec-17-text": "Tommel opp / ned: ",

          "step-0-header": "Hvordan spille",
          "step-0-text1": "I FYL.LA joiner alle samme rom-id, og legger inn spørsmål, dares, leker, konkurranser, osv . . . Bare fantasien setter grenser, vær kreativ!",

          "step-1": "Steg 1",
          "step-1-header1": "Legg til i rommet",
          "step-1-text1": "Skriv inn i tekstfeltet, og klikk deretter på “Legg til”",
          "step-1-header2": "Skrivesperre?",
          "step-1-text2": "Du kan også få tips til hva du kan legge til ved å åpne forslagsmenyen",

          "step-2": "Steg 2",
          "step-2-header1": "klikk på start!",
          "step-2-text1": "Når dere har nok innhold i rommet, kan dere klikke på \"Start\" på én av mobilene for å sette i gang!",

          "step-3": "Steg 3",
          "step-3-header1": "La mobilen gå på rundgang i rommet!",
          "step-3-text1": "En person leser høyt hva som står. Når det som står er gjort, gir du mobilen til venstre slik at de kan fortsette med neste!",

          "step-4": "Steg 4",
          "step-4-header1": "Legg inn underveis!",
          "step-4-text1": "Om du kommer på noe gøy i runden, er det bare å legge til mer i det samme rommet underveis."
        }
      },
      english: {
        translation: {
          "no-exist": "The room doesn't exist",
          "taken": "The room name is taken",
          "nothing": "Nothing has been added",

          "empty": "The room is empty 😭",

          "added": "Added",
          "language-changed": "Language changed",

          "new-room": "NEW ROOM",
          "join-room": "JOIN ROOM",

          "back": "BACK",
          "create": "CREATE",
          "room-id": "ROOM-ID",
          "start": "START",

          "how-to-play": "HOW TO PLAY",
          "how1": "In this drinking game, everyone joins the same room, and submits questions or dares.",
          "how2": "When you are ready, start the game on one device and pass it around.",
          "write-here": "WRITE HERE . . .",
          "add": "ADD",
          "next": "NEXT",

          "leave-room": "LEAVE ROOM",
          "done1": "DONE PLAYING?",
          "done2": "You haven't finished the round.",
          "keep-playing": "KEEP PLAYING",

          "privacy-policy": "PRIVACY POLICY",
          "terms-of-service": "TERMS OF SERVICE",
          "menu": "MENU",

          "tips": "Tips",

          "rec-1-preview": "Target he/she who . . .",
          "rec-2-preview": "Never have i ever",
          "rec-3-preview": "Red flag, dealbreaker, ok",
          "rec-4-preview": "Whom in the room . . .",
          "rec-5-preview": "Category game",
          "rec-6-preview": "Waterfall",
          "rec-7-preview": "All the girls . . .",
          "rec-8-preview": "All the boys . . .",
          "rec-9-preview": "Everyone who . . .",
          "rec-10-preview": "Single people must . . .",
          "rec-11-preview": "Everyone in a relationship . . .",
          "rec-12-preview": "The youngest . . .",
          "rec-13-preview": "The oldest . . .",
          "rec-14-preview": "Shot",
          "rec-15-preview": "Give out 3 sips . . .",
          "rec-16-preview": "Fuck, marry, kill . . .",
          "rec-17-preview": "Thumbs up or down",



          "rec-1-text": "He/she who ",
          "rec-2-text": "Never have i ever ",
          "rec-3-text": "Red flag, dealbreaker, ok: ",
          "rec-4-text": "Whom in the room ",
          "rec-5-text": "Category game: ",
          "rec-6-text": "Waterfall",
          "rec-7-text": "Girls ",
          "rec-8-text": "Boys ",
          "rec-9-text": "Everyone who ",
          "rec-10-text": "Single people must ",
          "rec-11-text": "Everyone in a relationship ",
          "rec-12-text": "The youngest ",
          "rec-13-text": "The oldest ",
          "rec-14-text": "Shot",
          "rec-15-text": "Give out 3 sips ",
          "rec-16-text": "Fuck, marry, kill: ",
          "rec-17-text": "Thumbs up or down: ",


          "step-0-header": "How to Play",
          "step-0-text1": "In FYL.LA, everyone joins the same room ID, and adds questions, dares, games, competitions, etc. . . . Only your imagination sets the limits, be creative!",

          "step-1": "Step 1",
          "step-1-header1": "Add to the Room",
          "step-1-text1": "Enter in the text field, and then click on “Add”",
          "step-1-header2": "Writer's Block?",
          "step-1-text2": "You can also get suggestions for what to add by opening the tips menu",

          "step-2": "Step 2",
          "step-2-header1": "Click on start!",
          "step-2-text1": "When you have enough content in the room, you can click on \"Start\" on one of the phones to get going!",

          "step-3": "Step 3",
          "step-3-header1": "Pass the phone around the room!",
          "step-3-text1": "One person reads aloud what is written. When they are done, pass the phone to the left so they can continue with the next one!",

          "step-4": "Step 4",
          "step-4-header1": "Add along the way!",
          "step-4-text1": "If you think of something fun along the way in the round, you can safely add more in the same room!"

        }
      }
    },
    lng: "norsk", // if you're using a language detector, do not define the lng option
    fallbackLng: "norsk",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;