import React, { useState, useEffect } from "react";
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StartScreen } from "./StartScreen";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { NoPage } from "./NoPage";
import { TermsOfService } from './TermsOfService';




function App() {
    

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<StartScreen />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-of-service" element={<TermsOfService />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
      </BrowserRouter>
    );
}

export default App;