import './ListItem.css';
import '../App.css';
import iconPlus from '../photos/plus.svg'

export function ListItem(props){

    return(
        <div className='list-item-container divider' onClick={() => props.setRoomInput(props.recText)}>
            <div className='text-h4'>
                {props.recPreview}
            </div>
            <img style={{ paddingTop: "3px" }} src={iconPlus} alt="" />
        </div>
    )
}