import React from 'react'
import './App.css';


export function NoPage(props) {
    return(
        <div className="App">
            <div style={{fontSize: "230px"}} className='countdown-m'>404</div>
            <div className='background'></div>
        </div>
    )
}